import React, { useEffect, useState } from "react";
import { GET_PARIKSHA_TEST_BY_UUID } from "../../../../app/service/pariksha-test.service";
import { toast } from "react-toastify";
import { GET_CONTENT_QUESTIONS } from "../../../../app/service/content-module/question-service";
import { GET_TOPIC_FITERS_LIST } from "../../../../app/service/abhyas-worksheet-filter.service";
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import { CREATE_ABHYAS_QUESTION } from "../../../../app/service/abhyas-question.service";
import ContentPreviewQuestions from "../preview-popup";
import TESTICON from "../../../../institute-dashboard-assets/images/Success_test.png"


interface Props {
    test_uuid: any;
    setBackQuestionsList?: (e: string) => void;
}

export const ContentWorksheetAddQuestion: React.FC<Props> = (props: Props) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [testData, setTestData] = useState<any>({});
    const [topicsList, setTopicsList] = useState<any>([]);
    const [savedQuestions, setSavedQuestions] = useState<any>([]);
    const [questionsList, setQuestionsList] = useState<any>([]);
    const [testPreviewPopup, setTestPreviewPopup] = useState<boolean>(false);
    const [testCreationPopup, setTestCreationPopup] = useState<boolean>(false);

    useEffect(() => {
        fetchAllData();

    }, []);
    const onClickBackTest = (data: any) => {
        if (props?.setBackQuestionsList) {
            props?.setBackQuestionsList('back');
        }
    }
    const fetchAllData = async () => {
        setLoading(true);
        try {
            const testData = await getTestData();
            await getTopicList(testData);
            await getQuestionsList(testData);
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }
    const getTestData = async () => {
        try {
            const payLoad = {
                uuid: props?.test_uuid,
            }
            const res: any = await GET_PARIKSHA_TEST_BY_UUID(payLoad);
            if (res) {
                if (res.status === 'fail') {
                    toast.error(res?.err);

                } else {
                    const data = res?.rows;
                    data?.forEach((element: any) => {
                        element.exam_type = element?.exam_type?.replace(/_/g, ' ');
                    });
                    setSavedQuestions(data[0]?.questions_data);
                    setTestData(data[0]);
                    return data[0];
                }
            }
        } catch (error) {

        }
    }
    const getQuestionsList = async (testData: any) => {
        try {
            const payLoad = {
                board_uuid: testData?.board_uuid,
                medium_uuid: testData?.medium_uuid,
                standard_uuid: testData?.standard_uuid,
                subject_uuid: testData?.subject_uuid,
                chapter_uuid: testData?.chapter_uuid ? testData?.chapter_uuid : "",
                content_type: "QUESTION",
                sorting_type: "ASC",
                validation_status: "VERIFIED",

            }
            GET_CONTENT_QUESTIONS(payLoad, 0, 1000000).then((res: any) => {
                if (res) {
                    if (res.status === 'fail') {
                        toast.error(res.err);
                        setLoading(false);
                    } else {
                        const data = res?.data;
                        if (data && data?.length > 0) {
                            const updatedSavedQuestions = data.map((question: any) => ({
                                ...question,
                                select_question: false,
                            }));
                            setQuestionsList(updatedSavedQuestions);
                        } else {
                            setQuestionsList([]);
                        }
                    }
                }
            });
        } catch (error) {

        }
    }


    const getTopicList = async (testData: any) => {
        const payLoad = {
            board_uuid: testData?.board_uuid,
            medium_uuid: testData?.medium_uuid,
            standard_uuid: testData?.standard_uuid,
            subject_uuid: testData?.subject_uuid,
            chapter_uuid: testData?.chapter_uuid,
            topic_uuid: testData?.chapter_uuid
        }
        const res: any = await GET_TOPIC_FITERS_LIST(payLoad?.board_uuid, payLoad?.medium_uuid, payLoad?.standard_uuid, payLoad?.subject_uuid, payLoad?.chapter_uuid);
        if (res) {
            if (res.status === 'fail') {
                toast.error(res?.err);
            } else {
                const data = res['rows'];
                setTopicsList(data);
            }
        }
    }
    function onViewQuestion(name: any) {
        return { __html: name };
    }
    const handleSubmitQuestions = async () => {
        const updatedSavedQuestions = savedQuestions.map((question: any) =>
            question.select_question
                ? { ...question, question_used_count: (question.question_used_count || 0) + 1 }
                : question
        );
        const payLoad = {
            questions_data: updatedSavedQuestions,
        }
        const res: any = await CREATE_ABHYAS_QUESTION(payLoad, props?.test_uuid);
        if (res.status === 'fail') {
            toast.error(res?.err);
        } else {
            toast.success(res.message);
            setTestCreationPopup(true);
        }
    }

    const onSelectQuestion = (questionIndex: number) => {
        setQuestionsList((prevQuestions: any) =>
            prevQuestions?.map((question: any, qIndex: number) =>
                qIndex === questionIndex
                    ? { ...question, select_question: !question.select_question, mode: "add" }
                    : question
            ),

        );
    };

    const handleMovedQuestionToTest = () => {
        const selectedQuestions = questionsList.filter((question: any) => question.select_question);
        const uniqueQuestions = selectedQuestions.filter(
            (question: any) =>
                !savedQuestions.some((savedQuestion: any) => savedQuestion.id === question.id)
        );
        setSavedQuestions((prevSavedQuestions: any) => [
            ...prevSavedQuestions,
            ...uniqueQuestions
        ]);
        setQuestionsList((prevQuestionsList: any) =>
            prevQuestionsList.map((question: any) =>
                question.select_question
                    ? { ...question, select_question: false }
                    : question
            )
        );
    };


    const removeQuestion = (questionIndex: number) => {
        setSavedQuestions((prevSavedQuestions: any) => {
            const removedQuestion = prevSavedQuestions[questionIndex];
            const updatedSavedQuestions = prevSavedQuestions.filter(
                (_: any, qIndex: number) => qIndex !== questionIndex
            );

            if (removedQuestion) {
                setQuestionsList((prevQuestionsList: any) =>
                    prevQuestionsList.map((question: any) =>
                        question.id === removedQuestion.id
                            ? { ...question, select_question: false }
                            : question
                    )
                );
            }

            return updatedSavedQuestions;
        });
    };

    const getLevelBg = (level: string) => {
        switch (level) {
            case "EASY":
                return "easy_bg";
            case "MEDIUM":
                return "medium_bg";
            case "HARD":
                return "hard_bg";
            default:
                return "easy_bg";
        }
    }
    const getContentValue = (type: string) => {
        switch (type) {
            case "AUDIO":
                return <span className="f18">&#128362;</span>;
            case "VIDEO":
                return <i className="fa fa-youtube-play f16"></i>;
            default:
                return "";
        }
    }
    return (
        <div>
            {testPreviewPopup &&
                <div className={`${!testPreviewPopup && "d_none"}`}>
                    <ContentPreviewQuestions questionsList={savedQuestions} setShowPopUp={setTestPreviewPopup} topic_questions={false} />
                </div>
            }
            <div
                className={`modal fade ${testCreationPopup ? "show" : "false"}`}
                style={{ display: testCreationPopup ? "block" : "none" }}
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
            >
                <div className="test_create_modal-dialog_popup" role="document">
                    <div className="modal-content pariksha-model-content">
                        <div className="modal-body  payment_user_popup_info position-relative">
                            <button type="button" className="close popup-close position-absolute" data-dismiss="modal" aria-label="Close" onClick={() => setTestCreationPopup(false)} style={{ right: "20px" }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <div className='payment_user_popup_block '>
                                <div className='mt-1 d-flex justify-content-center'>
                                    <div className="text-center ">
                                        <img src={TESTICON} />
                                        {testData?.questions_data?.length > 0 ?
                                            <h5 className="test_cls_blue f22">Worksheet Updated Successfully</h5>
                                            :
                                            <h5 className="test_cls_blue f22">Worksheet Successfully Added!</h5>
                                        }
                                        <p className="mb-1 mt-4 f18">{testData?.name}</p>
                                        <p className="mb-1 f18">({testData?.level})</p>
                                        <p className="my-4 f16">Questions: {testData?.questions} | Time {testData?.time} mins</p>
                                    </div>
                                </div>
                                <div className='mt-4'>
                                    <div className='mt-3 d-flex' style={{ justifyContent: 'center' }}>
                                        <button className='btn payment_user_btn  px-4 rounded-12 payment_mem_text py-1 f16 fw-bold' onClick={onClickBackTest}>Create New Worksheet</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {loading &&
                <div className="text-center p-5">
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            }
            {!loading &&
                <div>
                    <h5 className="f18 mb-2">Add Questions to Worksheet</h5>
                    <div className="row mt-3 border-top border-bottom ps-3 py-1">
                        <div className=" col-2"><p className="mb-0 f16">Worksheet Number: <span className="fw-bold">1{testData?.number}</span></p></div>
                        <div className=" col-3"><p className="mb-0 f16">Worksheet Name: <span className="fw-bold">{testData?.name}</span></p></div>
                        <div className="  col-2"> <p className="mb-0 f16">Difficulty Level: <span className="fw-bold">{testData?.level}</span></p></div>
                        <div className="  col-2"> <p className="mb-0 f16">Total Questions: <span className="fw-bold">{testData?.questions}</span></p></div>
                        <div className=" col-3"><p className="mb-0 f16">Worksheet Set number: <span className="fw-bold">{testData?.set_number && testData?.set_number}</span></p></div>
                    </div>
                    <div className="row mt-4 d-flex justify-content-center border-bottom pb-4 border-2">
                        <div className='col-md-5'>
                            <p className="mb-0 f16">Topic: <span className="fw-bold">{topicsList[0]?.name}</span></p>
                        </div>
                    </div>
                    <div>
                        <div className="row mx-2 mt-3">
                            <div className="col-5">
                                <div className="d-flex justify-content-between mb-2">
                                    <p className="mb-1 f16 fw-bold">Total available questions</p>
                                    <p className="mb-1 f16 fw-bold">Ques. Used Count</p>
                                </div>
                                <div className="questions_add_bx">
                                    <MathJaxContext>
                                        <MathJax>
                                            {questionsList?.map((data: any, i: number) => (
                                                <div className="row " key={i}>
                                                    <div className={`col-1 border border-white  ${getLevelBg(data?.difficulty_level)}`}>
                                                        <p className={`mb-0 fw-bold pt-2`}>{data?.difficulty_level ? data?.difficulty_level?.charAt(0) : getContentValue(data?.content_type)}</p>
                                                    </div>
                                                    <div className={`col-10 ${data?.select_question ? "select_ques_bg" : "test_ques_bg"} border border-white cursor-pointer`} onClick={() => onSelectQuestion(i)}>
                                                        <div className="d-flex pt-2 img_ck ">
                                                            <p className="f16 fw-bold">{i + 1}. </p> <p className="mb-0" style={{ maxWidth: "100%" }} dangerouslySetInnerHTML={onViewQuestion(data?.question)}></p></div>
                                                    </div>
                                                    <div className="col-1 px-0 border border-secondary border-1 bg-white">
                                                        <p className="mb-0 ps-3 pt-2">{data?.question_used_count ? data?.question_used_count : 0}</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </MathJax>
                                    </MathJaxContext>
                                    {questionsList?.length == 0 && <p className="text-center f14 mb-0">No Questions found</p>}
                                </div>
                            </div>
                            <div className="col-1 mt-5 pt-5">
                                {questionsList?.length > 0 && <div className="px-3 mt-5 pt-5">
                                    <div onClick={handleMovedQuestionToTest}>
                                        <p className=" mb-0 text-danger drag_bx  cursor-pointer mx-1"><span className="f22 p-1">➜</span></p>
                                        <p className="f18 fw-bold  ms-1">ADD</p>
                                    </div>
                                </div>}
                            </div>
                            <div className="col-5">
                                <p className="mb-2 f16 fw-bold">Questions added to the Worksheet (<span className="text-success f16 fw-bold">{savedQuestions?.length}</span>/<span>{testData?.questions}</span>)</p>
                                <div className="questions_add_bx">
                                    <MathJaxContext>
                                        <MathJax>
                                            {savedQuestions?.map((data: any, i: number) => (
                                                <div className="row" key={i}>
                                                    <div className={`col-1 border border-white  ${getLevelBg(data?.difficulty_level)}`}>
                                                        <p className={`mb-0 fw-bold pt-2`}>{data?.difficulty_level ? data?.difficulty_level?.charAt(0) : getContentValue(data?.content_type)}</p>
                                                    </div>
                                                    <div className="col-10 test_ques_bg border border-white">
                                                        <div className="d-flex pt-2 img_ck ">
                                                            <p className="f16 fw-bold">{i + 1}. </p> <p className="mb-0" style={{ maxWidth: "100%" }} dangerouslySetInnerHTML={onViewQuestion(data?.question)}></p></div>
                                                    </div>
                                                    <div className="col-1">
                                                        <p> <span className="text-danger f22 cursor-pointer" onClick={() => removeQuestion(i)}>⨂</span></p>
                                                    </div>
                                                </div>))}
                                        </MathJax>
                                    </MathJaxContext>
                                    {savedQuestions?.length == 0 && <p className="text-center f14 mb-0">No Questions found</p>}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-center mt-5">
                            <button className="btn btn-secondary px-4 rounded-12 f14 py-1 fw-bold" onClick={onClickBackTest}>Back</button>
                            <button className="btn btn-warning px-4 rounded-12 f14  py-1 mx-3 text-white fw-bold" disabled={savedQuestions?.length == 0} onClick={() => setTestPreviewPopup(true)}>Preview</button>
                            <button className="btn btn-primary px-4 rounded-12 f14  py-1 fw-bold" disabled={savedQuestions?.length == 0} onClick={handleSubmitQuestions}>Create Worksheet</button>
                        </div>
                    </div>
                </div>}
        </div>

    )
}