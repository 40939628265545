import { MathJax, MathJaxContext } from "better-react-mathjax";
import React, { useRef, useState } from "react";

interface Props {
    questionsList: any;
    setShowPopUp: any;
    topic_questions: boolean;
    handleClose?: (e?: boolean) => void;
}

const ContentPreviewQuestions: React.FC<Props> = (props: Props) => {
    const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});
    const audioRef = useRef<HTMLAudioElement>(null);
    const videoRef = useRef<HTMLVideoElement>(null);

    const handleClose = () => {
        stopMedia();
        if (props?.handleClose) {
            props?.handleClose(false);
        } else {
            props.setShowPopUp(false);
        }
    }

    function onViewQuestion(name: any) {
        return { __html: name };
    }
    const getAnswerValue = (anwerValue: string, optionList: any) => {
        const preData = optionList?.filter((item: any) => item?.option === anwerValue);
        if (preData) {
            return preData[0]?.value
        } else {
            return " ";
        }

    }
    const toggleCollapse = (index: number) => {
        setExpanded((prev) => ({
            ...prev,
            [index]: !prev[index],
        }));
    };
    const stopMedia = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
        }
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
        }
    };
    return (
        <div
            className={`modal fade ${true ? "show" : "false"}`}
            style={{ display: true ? "block" : "none" }}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
        >
            <div className="modal-dialog question_preview_dialog" role="document">
                <div className="modal-content">
                    <h5 className="text-center" style={{ top: "16px", position: "relative" }}>
                        Test Preview
                    </h5>
                    <div className="modal-body px-5 mx-2">
                        <MathJaxContext>
                            <MathJax>
                                <div className=" border ps-4 pe-3 pt-2 mt-3 question_view_height">
                                    {props?.topic_questions ? <div>
                                        {props?.questionsList?.map((topic: any, index: number) => (
                                            <div key={index}>
                                                {topic?.questions?.map((questionItem: any, i: number) => (
                                                    <div key={i}>
                                                        <div className='row col-md-12 mt-3'>
                                                            <div className='row'>
                                                                <div className='col-md-11'>
                                                                    <div className='d-flex'>
                                                                        <p className='fw-bold mb-0' style={{ fontSize: '22px' }}>
                                                                            Q{i + 1}:
                                                                        </p>
                                                                        <div className='ms-3 ps-1 img_ck'>
                                                                            <div className='d-flex'>
                                                                                {questionItem?.video_url && <video ref={videoRef} src={questionItem?.video_url} className='w-50 rounded-3 me-3' controls></video>}
                                                                                {questionItem?.audio_url && <audio ref={audioRef} src={questionItem?.audio_url} controls></audio>}
                                                                            </div>
                                                                            <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                                                <div dangerouslySetInnerHTML={onViewQuestion(questionItem.question)}></div>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {Array.isArray(questionItem?.options) && questionItem?.options?.map((options: any, j: number) => (
                                                                <div className='row' key={j}>
                                                                    <div className='col-md-1'>
                                                                        <span>{options.option}.</span>
                                                                    </div>
                                                                    <div className='col-md-6'>
                                                                        <span dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                                    </div>
                                                                </div>
                                                            ))}

                                                        </div>
                                                        <p
                                                            className="mb-1 f14 fw-bold text-primary cursor-pointer"
                                                            role="button"
                                                            aria-expanded={expanded[i] || false}
                                                            aria-controls={`collapse${i}`}
                                                            onClick={() => toggleCollapse(i)}
                                                        >
                                                            <u>{expanded[i] ? "Show less" : "Show more"}</u>
                                                        </p>
                                                        {expanded[i] && (
                                                            <div id={`collapse${i} img_ck`}>
                                                                <p className="d-flex mb-1">
                                                                    <span className="fw-bold mb-1 f16 me-2">Ans:</span>
                                                                    {questionItem?.correct_answer}.{" "}
                                                                    {questionItem?.correct_answer && (
                                                                        <span
                                                                            className="ms-2"
                                                                            dangerouslySetInnerHTML={onViewQuestion(
                                                                                getAnswerValue(questionItem?.correct_answer, questionItem.options)
                                                                            )}
                                                                        ></span>
                                                                    )}
                                                                </p>
                                                                <p className="d-flex mb-1">
                                                                    <span className="fw-bold mb-1 f16 me-2">Explanation:</span>
                                                                    {questionItem?.explanation && (
                                                                        <span
                                                                            className="ms-2"
                                                                            dangerouslySetInnerHTML={onViewQuestion(
                                                                                questionItem?.explanation
                                                                            )}
                                                                        ></span>
                                                                    )}
                                                                </p>
                                                            </div>
                                                        )}
                                                        <div className="d-flex justify-content-between">
                                                            <h6 className="text-danger mb-0 f14 fw-bold">Question Used Count: {questionItem?.question_used_count}</h6>
                                                            {questionItem?.question_info && <p className="mb-0 f16">({questionItem?.question_info})</p>}
                                                        </div>
                                                    </div>))}
                                            </div>
                                        ))}
                                    </div> : <div>

                                        {props?.questionsList && props?.questionsList?.map((questionItem: any, i: number) => (
                                            <div key={i} className="question-item">
                                                <div className="d-flex mb-2">
                                                    <p className="fw-bold" style={{ fontSize: "22px" }}>Q{i + 1}:</p>
                                                    {questionItem?.question &&
                                                        <div className="ms-3">
                                                            <div className='d-flex'>
                                                                {questionItem?.video_url && <video ref={videoRef} src={questionItem?.video_url} className='w-50 rounded-3 me-3' controls></video>}
                                                                {questionItem?.audio_url && <audio ref={audioRef} src={questionItem?.audio_url} controls></audio>}
                                                            </div>
                                                            <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                                <div dangerouslySetInnerHTML={onViewQuestion(questionItem.question)}></div>
                                                            </span>
                                                        </div>
                                                    }
                                                </div>
                                                {Array.isArray(questionItem?.options) && questionItem?.options?.map((option: any, j: number) => (
                                                    <div className="d-flex mb-2" key={j}>
                                                        <span>{option?.option}.</span>
                                                        <span className="ms-5" dangerouslySetInnerHTML={onViewQuestion(option?.value)} />
                                                    </div>
                                                ))}
                                                <p
                                                    className="text-primary cursor-pointer"
                                                    role="button"
                                                    aria-expanded={!!expanded[i]}
                                                    onClick={() => toggleCollapse(i)}
                                                >
                                                    <u>{expanded[i] ? "Show less" : "Show more"}</u>
                                                </p>
                                                {expanded[i] && (
                                                    <div className="details">
                                                        <p>
                                                            <strong>Ans:</strong> {questionItem?.correct_answer}{" "}
                                                            <span
                                                                dangerouslySetInnerHTML={onViewQuestion(
                                                                    getAnswerValue(questionItem?.correct_answer, questionItem?.options)
                                                                )}
                                                            />
                                                        </p>
                                                        {questionItem?.explanation && (
                                                            <p>
                                                                <strong>Explanation:</strong>{" "}
                                                                <span dangerouslySetInnerHTML={onViewQuestion(questionItem?.explanation)} />
                                                            </p>
                                                        )}
                                                    </div>
                                                )}

                                                <div className="d-flex justify-content-between">
                                                    <h6 className="text-danger">Question Used Count: {questionItem?.question_used_count}</h6>
                                                    {questionItem?.question_info && <p>({questionItem?.question_info})</p>}
                                                </div>
                                            </div>
                                        ))}
                                    </div>}
                                    {props?.questionsList?.length == 0 && <h6 className="text-center mt-3">No Data Found</h6>}
                                </div>
                            </MathJax>
                        </MathJaxContext>
                    </div>
                    <div className="modal-footer border-0 text-center d-block">
                        <button
                            type="button"
                            className="btn btn-primary  px-5 rounded-12  py-1"
                            data-dismiss="modal"
                            onClick={handleClose}
                        >
                            Ok
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContentPreviewQuestions;