import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify';
import { MathJaxContext, MathJax } from 'better-react-mathjax';
import TimerInfo from '../dashboard/mns/timer/timerinfo';
import { FETCH_PARIKSHA_USER_QUESTION_PAPER, UPDATE_PARIKSHA_USER_QUESTION_PAPER } from '../../app/service/pariksha-module-questions';

declare const window: any;


export const ParikshaMobileQuestionsForm = () => {
    const [questionsData, setQuestionsData] = useState<any>([]);
    const [loading, setLoading] = useState(false);
    const [startTimer, setStartTimer] = useState(false);
    const [canShowHeader, setShowHeader] = useState(false);
    const [startTime, setStartTime] = useState(new Date());
    const [alphabets, setAlphabets] = useState<any>(["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "l", "m", "n", "o", "p", "q", "r", "s", "t", "u", "v", "w", "x", "y", "z"]);
    const [canShowPopup, setCanShowPopup] = useState(false);
    const [minutes, setMinutes] = useState(60);
    const [seconds, setSeconds] = useState(0);
    let completedTime: string = "";
    let time_remain_storage = '';
    let timeUp = false;
    const questionRefs = useRef<(HTMLInputElement | null)[]>([]);
    const [appData, setAppData] = useState<any>({});
    const [timeRemainForApp, setTimeRemainForApp] = useState<string>('');
    const [webviewInitialized, setWebviewInitialized] = useState<boolean>(false);
    const [attemptQuestionCount, setAttemptQuestionCount] = useState<number>(0);
    const [currentQuestion, setCurrentQuestion] = useState<number>(0);
    const [showQuestions, setShowQuestions] = useState<boolean>(true);
    const [totalSeconds, setTotalSeconds] = useState<number>(0);


    useEffect(() => {

        const isIos = navigator.userAgent.includes('iPhone');
        if (isIos) {
            window.addEventListener("message", handleNativeMessage);
        } else {
            document.addEventListener("message", handleNativeMessage);
        }

        return () => {
            window.removeEventListener('message', handleNativeMessage);
        };
    }, []);


    useEffect(() => {
        if (!webviewInitialized) {
            setWebviewInitialized(true);
            sendMessageToApp({ action: 'init_webview', type: '', data: 'initialized' });
        }

    }, [webviewInitialized]);
    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (true) {
            interval = setInterval(() => {
                setTotalSeconds((prevSeconds) => prevSeconds + 1);
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [true]);

    function handleNativeMessage(message: any) {
        let data = JSON.parse(message.data);
        if (data?.key && data?.key === 'init') {
            setAppData(data);
            getTimeDetails(data);
            localStorage.setItem("token",
                data.token);
            setLoading(true);
            setTimeout(() => {
                setShowHeader(true);
                setStartTimer(true);
                setMinutes(data?.time);
                if (data?.questions) {
                    setQuestionsData(data?.questions);
                }
                setLoading(false);

            }, 100);
        } else {

        }
    }



    function getTimeDetails(data: any) {
        // const testStartDate: any = sessionStorage.getItem("abhyas_test_start_date");

        // return testStartDate ? testStartDate : null;
        const testStartDate: any = data.abhyas_test_start_date;
        let time = getTime(testStartDate);
        let timeMillis = moment(time, 'YYYY-MM-DD hh:mm:ss').toDate().getTime();
        let reqTimeMillis = 2 * 24 * 60 * 60 * 1000;
        let actualTimeToComplete = timeMillis + reqTimeMillis;
        let x = new Date(actualTimeToComplete)

        let currentTime = new Date().getTime();
        let timerResult = x.getTime() - currentTime;
        let tSeconds = Math.floor(timerResult / 1000);
        let hours = Math.floor(tSeconds / 3600);
        let minutes = Math.floor((tSeconds - (hours * 3600)) / 60); // get minutes
        let seconds = tSeconds - (hours * 3600) - (minutes * 60); //  get seconds
        setTimeRemainForApp((hours < 10 ? "0" + hours : hours) + ":" + (minutes < 10 ? "0" + minutes : minutes) + ":" + (seconds < 10 ? "0" + seconds : seconds) + ' hrs');

    }

    function getTime(time: any) {
        if (time !== 'null') {
            return time;
        } else {
            return time ? time : startTime;
        }
    }

    function onViewQuestion(name: any) {
        // let preElement = `<p class="mb-0">${name}</p>`
        return { __html: name };
    }

    function handleQuestionOptions(item: any, index: number, j: number) {
        const data = questionsData;
        if (data?.length == currentQuestion + 1) {
            data[index].checked = true;
        }
        for (let i = 0; i < alphabets.length; i++) {
            if (j === i) {
                data[index].answer = alphabets[i];
                // data[index].checked = true;
            }
        }
        setQuestionsData([...data]);
        sessionStorage.setItem("questionsData", JSON.stringify(data));

    }


    const handleCompleteSubmit = () => {
        let questionData = questionsData;
        questionData[currentQuestion].time_taken = timeTakenFormate(totalSeconds);
        questionData[currentQuestion].time_taken_ui = totalSeconds;
        const payload = {
            "user_code": appData?.user_code,
            "school_code": appData?.school_code,
            "subject_uuid": appData?.subject_uuid,
            "type": appData?.type,
            "exam_type": appData?.exam_type,
            "test_time": `${minutes}.0`,
            "chapter_uuid": appData?.chapter_uuid ? appData?.chapter_uuid : "",
            "question_paper_uuid": appData?.question_paper_uuid,
            "time_taken": calculateTimeDifference() ? calculateTimeDifference() : "",
            "questions": questionsData.length > 0 ? [...questionData] : questionsData
        }

        setLoading(true);
        UPDATE_PARIKSHA_USER_QUESTION_PAPER(payload).then((res: any) => {
            if (res) {
                if (res.status === 'fail') {
                    const errorValue = Array.isArray(res?.err) ? res?.err[0] : res?.err;
                    toast.error(errorValue);
                    setLoading(false);
                } else {
                    setLoading(false);
                    const data = res;
                    if (appData?.type === 'WORKSHEETS') {
                        sendMessageToApp({ action: 'show_popup', type: 'worksheet_submitted', data: '', test_result: data });
                        return;
                    }
                    sendMessageToApp({ action: 'show_popup', type: 'test_submitted', timeRemain: timeRemainForApp, test_result: data });
                }

            }
        });

    }
    const sendMessageToApp = (data: any) => {
        if (window?.ReactNativeWebView) {
            try {
                window?.ReactNativeWebView.postMessage(JSON.stringify(data));
            } catch (error) {

            }
        }
    }



    const calculateTimeDifference = () => {
        const initialTime = `${"59"}:${"59"}`
        const [initialMinutes, initialSeconds] = initialTime.split(':').map(Number);
        const [finalMinutes, finalSeconds] = completedTime.split(':').map(Number);
        const initialTimeInSeconds = Number(initialMinutes) * 60 + Number(initialSeconds);
        const finalTimeInSeconds = Number(finalMinutes) * 60 + finalSeconds ? Number(finalSeconds) : 0;
        const differenceInSeconds = Number(initialTimeInSeconds * 100) - Number(finalTimeInSeconds * 100);

        if (differenceInSeconds >= 60) {
            var mins = moment.utc(moment(initialTime, "mm:ss").diff(moment(completedTime, "mm:ss"))).format("mm.ss")
            sessionStorage.setItem("setTime", mins === 'Invalid date' ? `${minutes}.${seconds}` : mins.replace('00', '0'));
            return mins === 'Invalid date' ? `${minutes}.${seconds}` : mins.replace('00', '0')
        } else {
            sessionStorage.setItem("setTime", `0.${differenceInSeconds}`);
            return `0.${differenceInSeconds}`;
        }

    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>, currentIndex: number) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent page reload
            const nextIndex = currentIndex + 1;
            if (nextIndex < questionRefs.current.length) {
                questionRefs.current[nextIndex]?.focus();
            }
        }
    };
    const submitTest = () => {
        const attemptQuestionsLength = questionsData.filter((el: any) => el.checked);
        if (attemptQuestionsLength?.length > 0) {
            setAttemptQuestionCount(attemptQuestionsLength?.length);
            setCanShowPopup(true);
        } else {
            toast.error("Please attempt at least one question");
            setAttemptQuestionCount(0);
        }

    }

    const onClickCancel = () => {
        setCanShowPopup(false);
    }
    const getDomElements = (htmlString: any, tag: string) => {
        const parsedContent = new DOMParser().parseFromString(htmlString, 'text/html');
        const pElements = parsedContent.querySelectorAll('p');
        const imgElement: any = parsedContent.querySelector('img');
        if (tag === "p") {
            const paragraphs: any[] = [];
            pElements.forEach((pElement) => {
                paragraphs.push(`<p class="mb-0">${pElement.innerHTML}</p>`);
            });
            return <div dangerouslySetInnerHTML={{ __html: paragraphs?.join('') }} />
        }
        if (tag === "img") {
            if (imgElement) {
                return imgElement ? imgElement.getAttribute('src') : ''
            } else {
                return "";
            }

        }
        return "";
    }
    const selectQuestion = (questioNo: any) => {
        let preElement = parseInt(questioNo);
        const data = questionsData;
        if (data[currentQuestion].answer && (data[currentQuestion]?.id == questionsData[questionsData.length - 1]?.id)) {
            data[currentQuestion].checked = true;
        } else if (!data[currentQuestion].checked) {
            data[currentQuestion].answer = "";
            data[currentQuestion].checked = false;
        }
        data[currentQuestion].time_taken = timeTakenFormate(totalSeconds);
        data[currentQuestion].time_taken_ui = totalSeconds;
        setQuestionsData([...data]);

        setCurrentQuestion(preElement - 1);
    }
    const handlePrevious = () => {
        const data = questionsData;
        data[currentQuestion].time_taken = timeTakenFormate(totalSeconds);
        data[currentQuestion].time_taken_ui = totalSeconds;
        if (!data[currentQuestion].checked) {
            data[currentQuestion].answer = "";
            data[currentQuestion].checked = false;
        }
        setQuestionsData([...data]);
        setCurrentQuestion((prev) => prev - 1);
    };
    const handleClearSelection = () => {
        setCurrentQuestion(currentQuestion);
        const data = questionsData;
        data[currentQuestion].answer = "";
        data[currentQuestion].checked = false;
        setQuestionsData([...data]);
    };
    const handleNext = () => {
        const data = questionsData;
        data[currentQuestion].time_taken = timeTakenFormate(totalSeconds);
        data[currentQuestion].time_taken_ui = totalSeconds;
        if (data[currentQuestion].answer) {
            data[currentQuestion].checked = true;
        }
        setQuestionsData([...data]);
        setCurrentQuestion((prev) => prev + 1);
    };
    const timeTakenFormate = (totalSeconds: number) => {
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        return `${minutes}.${seconds}`;
    };
    return (
        <div className='app_bg_img scroll_div_block'>
            <div className="row  py-3">
                <div
                    className={`modal fade ${canShowPopup ? "show" : "false"}`}
                    style={{ display: canShowPopup ? "block" : "none" }}
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog pariksha-model-dialog" role="document">
                        <div className="modal-content pariksha-model-content">
                            <div className="modal-body parent-info pariksha-model-info">
                                <div className='mt-3'>
                                    <h5>You have attempted {attemptQuestionCount} out of {questionsData?.length} questions</h5>
                                </div>
                                <div className='mt-4 mb-3'>
                                    <h5>Do you want to submit the test ?</h5>
                                    <div className='mt-4'>
                                        <button className='btn test-btn' onClick={handleCompleteSubmit}>YES</button>
                                        <button className='btn test-btn' onClick={onClickCancel}>NO</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {loading &&
                    <div className="text-center p-5">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
                }
                {!loading && <div className='app_question_src'>
                    {canShowHeader &&
                        <div>
                            <div className='d-flex justify-content-between'>
                                <div><span className='ap_btns'>PARIKSHA</span></div>
                                <div className='me-3'>
                                    <p className="m-0 f14"> Time Limit :  {minutes} : {seconds < 9 ? `0${seconds}` : seconds}  mins</p>
                                    <div className='d-flex ms-3 ps-3'> <p className='mb-0 me-2 f14'>Time : </p>
                                        <TimerInfo
                                            customeTime={true}
                                            minutes={minutes}
                                            seconds={seconds}
                                            startTimer={startTimer}
                                            time_remain={(value: any) => {
                                                if (value === '') {
                                                    time_remain_storage = "5";
                                                } else {
                                                    time_remain_storage = value;
                                                    completedTime = time_remain_storage;
                                                }

                                            }}
                                            timeUp={() => {
                                                handleCompleteSubmit();
                                                timeUp = true;
                                            }} />
                                    </div>
                                </div>
                            </div>
                            <h2 className='text-center f18 mt-2'>{appData?.test_name && appData?.test_name} {appData?.chapter_name && (appData?.chapter_name)}</h2>

                        </div>}
                    <div className='app_qestion_board ' style={{ borderRadius: showQuestions ? "15px" : "5px" }}>
                        <h3 className=' f14 text-danger text-center'>Questions Board </h3>
                        <div className={`q_view  ${!showQuestions && "hide_q_block"}`}>
                            <div className=' app_question_no_block d-block justify-content-center'>
                                <div className="question-pattern ms-2 mb-1 ">
                                    {questionsData.map((questionNo: any, i: number) => (
                                        <button key={i} className={`app_q_no cursor-pointer  ${questionNo.id == currentQuestion + 1 ? "bg-secondary" : questionNo?.checked ? "q-primary" : "q-danger"}`} onClick={() => selectQuestion(questionNo.id)}>
                                            {questionNo.id}
                                        </button>

                                    ))}
                                </div>
                            </div>
                            <div className='d-flex  mt-1 justify-content-center '>
                                <div className='d-flex border-top border-secondary border-1 pt-1'>
                                    <div className='app_square-answer'></div>
                                    <div className='app_answer f16 fw-bold'>Answered</div>
                                </div>
                                <div className='d-flex ps-2 border-top border-secondary border-1 pt-1'>
                                    <div className='app_square-not-answer'></div>
                                    <div className='app_not-answer f16 fw-bold'>Not Answered</div>
                                </div>
                            </div>
                        </div>
                        <p className='m-0  app_queston_dwn' onClick={() => setShowQuestions(!showQuestions)} style={{ top: showQuestions ? "99%" : "77%" }}>
                            {!showQuestions ? <i className="fa  ps-2">&#xf103;</i>
                                : <i className="fa  ps-2">&#xf102;</i>}
                        </p>
                    </div>
                    {questionsData?.length > 0 && <div className='px-4 pb-3 mt-3'>
                        <MathJaxContext>
                            <MathJax>
                                <div className="">
                                    <div className='d-flex'>
                                        <div>
                                            <p className='fw-bold mb-0' style={{ fontSize: '22px' }}>
                                                Q{questionsData[currentQuestion]?.id}.
                                            </p>
                                        </div>
                                        <div className='ms-2'>
                                            <span className='fw-bold' style={{ fontSize: '22px' }}>
                                                <div className='img_ck' dangerouslySetInnerHTML={onViewQuestion(questionsData[currentQuestion]?.question)}></div>
                                            </span>
                                        </div>
                                    </div>
                                    <div className='ms-4 ps-3'>
                                        <form>
                                            {questionsData[currentQuestion]?.options?.map((options: any, j: number) => (
                                                <div className='d-flex pt-2 app_opt' key={j}>
                                                    <div >
                                                        <span className={`${getDomElements(options.value, "img") && "mt-5 "}`}>
                                                            <input className={`mb-0 cursor-pointer  ${getDomElements(options?.value, "img") && "mt-5 "}`} type="radio" name="option" checked={questionsData[currentQuestion]?.answer === alphabets[j] ? true : false} onChange={(e) => { handleQuestionOptions(options, currentQuestion, j) }}
                                                                onKeyDown={(e) => handleKeyDown(e, currentQuestion)}
                                                            // value={question.answer = question?.correct_answer}
                                                            />
                                                        </span>
                                                    </div>
                                                    <div className="ms-3">
                                                        <span className='img_ck_otp' dangerouslySetInnerHTML={onViewQuestion(options.value)}></span>
                                                    </div>
                                                </div>
                                            ))}
                                        </form>
                                    </div>
                                </div>
                            </MathJax>
                        </MathJaxContext>
                    </div>}
                    <footer className="mt-auto">
                        {questionsData?.length > 0 && <div className=' d-flex justify-content-around mx-2'>
                            <button className={`f12 btn  py-1 px-2   ${currentQuestion === 0 ? "btn-light text-dark" : "btn-warning text-white"}`} type='button' onClick={handlePrevious} disabled={currentQuestion === 0}>Back</button>
                            <button className={`f12 btn  ${currentQuestion === questionsData.length - 1 ? "btn-light" : "btn-primary"}  py-1 px-2`} type='button' onClick={handleNext} disabled={currentQuestion === questionsData.length - 1}>Save and Next</button>
                            <button className="f12 btn btn-secondary py-1 px-2 " type='button' onClick={handleClearSelection}>Clear Selection</button>
                            <button className="f12 btn btn-success py-2 px-2 " type='button' onClick={submitTest}>Submit Test</button>
                        </div>}
                    </footer>
                </div>}
            </div>
        </div>

    )
}
